/**
 * Single DataLayer - Primary part
 * Placed in the <head>, pushes data to sdl object
 * Secondary part (singleDL-secondary.js) is placed in footer
 * @version 1.1.0
 * @author Vendula Frýzlová <vendula.fryzlova@avast.com>, Jaroslav Slaby <jaroslav.slaby@avast.com>, Fedor Kušš <fedor.kuss@avast.com>
 * @copyright Avast Software s.r.o &copy;2022
 */

(function () {
    'use strict';

    // Function to safely retrieve values from nested objects
    function getSafe(obj, path) {
        var parts = path.split('.');
        var acc = obj;
        for (var i = 0; i < parts.length; i++) {
            if (acc && typeof acc[parts[i]] !== 'undefined') {
                acc = acc[parts[i]];
            } else {
                return 'unknown - ' + parts[i];
            }
        }
        return acc;
    }

    // Function to remove unwanted parameters from URL
    function removeUnwantedParamsFromUrl(urlParams) {
        var unwantedParams = ['ASWPARAM', 'aswparam', '_ga', '_gl'];
        for (var i = 0; i < unwantedParams.length; i++) {
            urlParams.delete(unwantedParams[i]);
        }
        var URLWithoutUnwantedParams = window.location.host + window.location.pathname + '?' + urlParams.toString();
        return URLWithoutUnwantedParams;
    }

    // Function to parse query parameters manually
    function getQueryParams() {
        var queryParams = {};
        var queryString = window.location.search.substring(1);
        var pairs = queryString.split("&");
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split("=");
            if (pair[0]) {
                queryParams[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
            }
        }
        return queryParams;
    }

    // Function to check if URL parameters have values
    function checkURLParams(params) {
        var checkedParams = {};
        for (var key in params) {
            // Only add parameters that have a value
            if (params.hasOwnProperty(key) && params[key]) {
                checkedParams[key] = params[key];
            }
        }
        return checkedParams;
    }

	window.sdl = window.sdl || [];

	try {
		// Check for OptanonConsent cookie and push cmpId to sdl
		if (/OptanonConsent=.*?(consentId=(.*?)(?=&))/.test(document.cookie)) {
			sdl.push({
				client: {
					cmpId: document.cookie.match(/OptanonConsent=.*?(consentId=(.*?)(?=&))/)[2]
				}
			});
		}

		// Check for NotLandingPage&groups cookie and push consentGroups to sdl
		if (/(?<=NotLandingPage&groups=|groups=).*?(?=&|;|$)/.test(document.cookie)) {
			sdl.push({
				consentGroups: document.cookie.match(/(?<=NotLandingPage&groups=|groups=).*?(?=&|;|$)/)[0].replace(/%3A/g, ':').replace(/%/g, ',').replace(/2C/g, ' ')
			});
		}

		// Push session and server information to sdl
		sdl.push({
			session: {
				countryCode: getSafe(sdlObj, 'session.countryCode'),
				secChUa: (function () {
					if (typeof navigator.userAgentData !== 'undefined' && navigator.userAgentData.brands.length > 0) {
						return navigator.userAgentData.brands;
					} else {
						return [];
					}
				})(),
				platform: getSafe(window, 'avastGlobals.detect.os.name'),
				mobile: (function () {
					if (typeof navigator.userAgentData !== 'undefined') {
						return navigator.userAgentData.mobile;
					} else {
						return '-';
					}
				})()
			},
			server: {
				provider: 'CMS',
				platform: 'WEB',
				env: window.location.host.indexOf('web-preview') === 0 ? 'dev' : 'prod',
				dataCenter: '-',
				sdlVersion: '2021-05-05/v1'
			}
		});

		// Use manual query parameter parsing for compatibility
		var params = getQueryParams();
		// Check if URL parameters have values
		var checkedParams = checkURLParams(params);

		// Push screen information to sdl
		sdl.push({
			event: 'screen',
			screen: {
				location: {
					url: window.location.search ? removeUnwantedParamsFromUrl(new URLSearchParams(window.location.search)) : window.location.host + window.location.pathname,
					woParams: window.location.host + window.location.pathname,
					protocol: window.location.protocol.replace(':', ''),
					path: '/' + getSafe(sdlObj, 'screen.locale') + '/' + getSafe(sdlObj, 'screen.path'),
					hostname: getSafe(sdlObj, 'screen.hostname'),
					hash: window.location.hash,
					params: window.location.search ? checkedParams : ''
				},
				locale: getSafe(sdlObj, 'screen.locale'),
				screenCategory: getSafe(sdlObj, 'screen.screenCategory'),
				title: document.title,
				type: getSafe(sdlObj, 'screen.fileName').indexOf('error-page.php') !== -1 ? 'error' : getSafe(sdlObj, 'screen.type'),
				name: getSafe(sdlObj, 'screen.name'),
				lineOfBusiness: getSafe(sdlObj, 'screen.lineOfBusiness'),
				screenId: getSafe(sdlObj, 'screen.screenId')
			}
		});

	} catch (error) {
		console.error(error);
		// Push error information to sdl
		sdl.push({
			system: {
				error: {
					type: error.name,
					message: error.message,
					description: 'singleDL-primary.js'
				}
			},
			event: 'system.error'
		});
	}
})();
